/* TitTap Editor Design */
.ProseMirror {
  padding: 10px;
  border-top: 1px solid grey;
  background: white;
  border-radius: 0 0 5px 5px;
  min-height: 100px;
}
.ProseMirror:focus {
  border: none;
  outline: 1px solid aqua;
}

.ProseMirror > * + * {
  margin-top: 0.75em;
}

.ProseMirror ul,
.ProseMirror ol {
  padding: 0 2rem;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  line-height: 1.1;
}

.ProseMirror code {
  background-color: rgba(#616161, 0.1);
  color: #616161;
}

.ProseMirror pre {
  background: #0d0d0d;
  color: #fff;
  font-family: "JetBrainsMono", monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}
.ProseMirror code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}

.ProseMirror img {
  max-width: 100%;
  height: auto;
}

.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 3px solid #999999;
}

.ProseMirror hr {
  border: none;
  border-top: 3px solid #999999;
  margin: 2rem 0;
}

/* ............................ */

.menuBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid #bdbcbd;
  border-bottom: 0px;
}
.menuBar button {
  background-color: transparent;
  border: none;
  border-radius: 0.4rem;
  color: #0d0d0d;
  height: 1.75rem;
  margin-right: 0.25rem;
  padding: 0.25rem;
  width: 1.75rem;
  margin: 5px;
}

.menuBar button:hover {
  background: #000;
  color: #fff;
}

.heading3 {
  font-size: 15px;
}

button.is_active {
  background: #000;
  color: #fff;
}

.menuBar .divider {
  background-color: transparent;
  border: none;
  border-radius: 0.4rem;
  color: #0d0d0d;
  height: 1.75rem;
  margin-right: 0.25rem;
  padding: 0.25rem;
  width: 1.75rem;
  margin: 5px;
}
.ProseMirror{
  border: none;
}
.ProseMirror:focus {
  outline: 1px solid transparent;
}
