@import "./colors.scss";

.box-default {
  border: 1px solid #dcdbdc !important;
  border-radius: 5px;
}

/* BUTTON PRIMARY */
.MuiButton-containedPrimary {
  padding: 8px 22px !important;
  border-radius: 6px !important;
  color: rgb(255, 255, 255) !important;
  background-color: $primary !important;
  box-shadow: rgb(9 30 66 / 12%) 0px 1px 3px !important;
}

/* BUTTON SECONDARY */
.MuiButton-textSecondary {
  padding: 8px 22px !important;
  border-radius: 6px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: transparent !important;
}

/* checked */
.MuiSwitch-colorPrimary.Mui-checked {
  color: $primary !important;
}

.MuiSwitch-track {
  background: $primary !important;
}

/* Link */
.MuiTypography-colorPrimary {
  color: $primary !important;
}

/* Menu */
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background: $primaryOpacity !important;
}

.MuiListItem-root.MuiListItem-gutters:active {
  color: #000 !important;
}

.p-20 {
  padding: 20px;
}

.p-0 {
  padding: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-2 {
  margin-top: 20px !important;
}

.ml-2 {
  margin-left: 20px;
}

.mr-2 {
  margin-right: 20px;
}

.mb-2 {
  margin-bottom: 20px !important;
}

.w-100 {
  width: 100% !important;
}

.MuiTableCell-head {
  font-size: 12px !important;
}

.alert-success {
  background-color: $success;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 6px !important;
  color: #000;
}

.alert-danger {
  background-color: $danger;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 6px !important;
  color: #000;
}

a.MuiButtonBase-root:hover {
  color: #000 !important;
}

/* DASH background */
.css-fxbtpg{
  background-color: #f5f4f9 !important;
}
