@import "./index.scss";

.txt-bold
{
    font-weight: bold;
}

.txt-center{
    text-align: center !important;
    justify-content: center !important;
    border: 1px solid rgb(240,240,240);
}
